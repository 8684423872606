import Link from 'next/link';
import PageLayout from 'layouts/PageLayout'
import { HandleResources } from 'services/resources.service'
import { GetCachedData, GetMetaData } from 'services/data.service'
import Footer from 'features/Footer'
import Hero from 'features/Hero'
import Masthead from 'features/Masthead'
import Resources from 'features/Resources'
import Spacer from 'basics/Spacer'
import Container from 'containers/Container'

const Custom404 = ({
  topNav,
  mainNav,
  pageData,
  meta,
  footerContent,
  applicationSettings = {}
}) => {
  return (
    <PageLayout meta={meta} applicationSettings={applicationSettings}>
      <Masthead topLinks={topNav?.links || []} mainLinks={mainNav?.links || []} />

      <Hero
        data={{
          headline: 'Page not found'
        }}
      />

      <Spacer size={10} mdSize={5} />

      <Container className="text-align-center intro-paragraph">
        <p>
          But we can help get you there. To reach our homepage, please use{' '}
          <Link href="/"><a>firstbusiness.bank</a></Link>
        </p>
        <p>
          You can also <Link href="/contact-us"><a>contact us</a></Link> for help.
        </p>
      </Container>

      <Spacer size={20} mdSize={13} />

      <Resources
        resources={pageData?.resources || []}
        headline={`Resources`}
        linkText="Visit our Resource Center"
        targetPage="/resource-center"
        className="mb-20 md-mb-13"
      />

      <Footer
        navLinks={topNav?.links || []}
        footerLinks={mainNav?.links || []}
        footerContent={footerContent}
      />
    </PageLayout>
  )
}

export default Custom404

export async function getStaticProps(context) {
  let pageData = {
    pageData: {
      title: 'Page Not Found'
    },
    meta: {
      title: 'Page Not Found'
    }
  }
  pageData.pageData = await HandleResources({ pageData: pageData.pageData, force: true })
  pageData = GetCachedData(pageData)
  const config = {
    props: pageData || {}
  }

  return config
}
