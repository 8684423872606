import { readFileSync } from 'fs'
import { Flatten, PickFirst, AddTrailingSlash } from './utils'
const Config = {
  pathsFile: 'data/paths.json',
  cachedData: 'data/cache.json'
}
import { GQLClient } from 'services/graphql'

export const GetCachedData = (data) => {
  const cachedData = JSON.parse(readFileSync(Config.cachedData).toString())
  Object.entries(cachedData).forEach(([key, value]) => (data[key] = value))
  data.topNav = Flatten(PickFirst(data.queryTopNavContents))
  data.mainNav = Flatten(PickFirst(data.queryMainNavContents))
  //data.topics = data.queryContentTopicContents?.map((topic) => Flatten(topic)) || []
  data.footerContent = Flatten(PickFirst(data.queryFooterContentContents))
  data.applicationSettings = Flatten(PickFirst(data.queryApplicationSettingsContents))

  return data
}

export const GetMetaData = (data = {}, pageType = '') => {
  if (!data) {
    data = { flatData: {} }
  }
  data = Array.isArray(data) ? PickFirst(data) : data
  return {
    title: data?.flatData ? (data.flatData.metaTitle || data.flatData.name) : data?.name || '',
    background: data?.flatData
      ? Flatten(PickFirst(data?.flatData?.pageBackground))
      : PickFirst(data?.pageBackground) || {},
    description: (data?.flatData ? data.flatData.metaDescription : data?.metaDescription) || '',
    url: AddTrailingSlash((data?.flatData ? data.flatData.url : data?.url) || ''),
    image: data?.flatData
      ? Flatten(PickFirst(data?.flatData?.metaImage))
      : PickFirst(data?.metaImage) || {},
    pageType,
    noIndex: data?.flatData?.noIndex || null
  }
}

export const FetchAllData = async (query, key) => {
  const fullData = []
  let total = 0
  const fetchData = async () => {
    const client = await GQLClient()
    const data = await client.request(query(total))
    total += data[key].items.length
    data[key].items.forEach((item) => {
      fullData.push(Flatten(item))
    })
    if (total < data[key].total) {
      return await fetchData()
    } else {
      return fullData
    }
  }

  return await fetchData()
}

export function GetSortMethod() {
  var _args = Array.prototype.slice.call(arguments)
  return function (a, b) {
    for (var x in _args) {
      var ax = a[_args[x].substring(1)]
      var bx = b[_args[x].substring(1)]
      var cx

      ax = typeof ax == 'string' ? ax.toLowerCase() : ax / 1
      bx = typeof bx == 'string' ? bx.toLowerCase() : bx / 1

      if (_args[x].substring(0, 1) == '-') {
        cx = ax
        ax = bx
        bx = cx
      }
      if (ax != bx) {
        return ax < bx ? -1 : 1
      }
    }
  }
}

export const MakeWeightedSort = (weight, name) => {
  const counterWeight = 10
  return new Array(counterWeight + weight).fill('A').join('')
}
