const path = require('path')
require('dotenv').config({ path: '.env.local' })

const fetch = require('isomorphic-unfetch')
module.exports = {
  fetchToken: () => {
    return new Promise((res) => {
      const endpoint = `${process.env.SQUIDEX_HOST}${process.env.SQUIDEX_TOKEN_URI}`

      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-access-token': process.env.API_TOKEN
      }
      if (process.env.SHOW_UNPUBLISHED) {
        headers['X-UNPUBLISHED'] = true
      }

      var options = {
        method: 'POST',
        headers: headers,
        body: new URLSearchParams({
          grant_type: 'client_credentials',
          client_id: process.env.SQUIDEX_CLIENT_ID,
          client_secret: process.env.SQUIDEX_CLIENT_SECRET,
          scope: process.env.SQUIDEX_SCOPE
        })
      }

      fetch(endpoint, options)
        .then((r) => r.json())
        .then((response) => {
          res(response)
        })
        .catch((err) => {
          console.log(err)
        })
    })
  }
}
