import dotenv from 'dotenv'
dotenv.config({ path: '.env.local' })
import { GraphQLClient } from 'graphql-request'
import Auth from './auth'

export const GQLClient = () => {
  return new Promise((res) => {
    Auth.fetchToken()
      .then((resp) => {
        const authData = resp
        const headers = {
          authorization: `Bearer ${authData.access_token}`,
          'x-access-token': process.env.API_TOKEN
        }
        if (process.env.IS_PREVIEW === 'true' || process.env.IS_PREVIEW === true) {
          headers['X-Unpublished'] = 1
        }
        res(
          new GraphQLClient(process.env.SQUIDEX_URI, {
            headers
          })
        )
      })
      .catch((err) => console.log(err))
  })
}
